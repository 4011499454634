import { useLocation, useNavigate } from "react-router-dom";
import { bookIcon, earthIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  activeFav,
  astrology,
  astrologyActive,
  community,
  communityActive,
  fav,
  home,
  homeActive,
} from "../../assets/navicons";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

const hideNavBlacklist = [
  "/characters/create",
  "/community/thread",
  "/home/:categoryId/:documentId",
  "/play",
  "/ota-score",
  "/check-response",
];

export default function Layout({ children }) {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const [curLang, setCurLang] = useState(language);
  const isMobile = window.innerWidth < 450;
  const userId = localStorage.getItem("USER_ID");
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");
  const isUserAuthenticated =
    userId && userId?.length > 0 && userId !== "undefined";
  const navigate = useNavigate();
  const location = useLocation();

  const shouldNavHide = hideNavBlacklist.some((path) =>
    location.pathname.includes(path)
  );

  useEffect(() => {
    (async () => {
      if (!userId) return;
      const userInfoAPIRes = await fetch(
        ` https://api.be-native.life/api/users/${userId}`
      );
      const userInfo = await userInfoAPIRes.json();

      const date = userInfo?.dob?.split("-")?.[2];
      const month = userInfo?.dob?.split("-")?.[1];
      const year = userInfo?.dob?.split("-")?.[0];

      const finalTokenResponse = await fetch(
        ` https://api.be-native.life/api/cognito/refresh_access_token?refresh_token=${refreshToken}`,
        {
          method: "POST",
        }
      );
      const finalTokenData = await finalTokenResponse.json();
      const tokenForHeader = finalTokenData?.access_token;

      const apiResponse = await fetch(
        ` https://api.be-native.life/api/friends/friends_data/${year}/${month}/${date}?lang=${language}`,
        {
          headers: {
            ...(!!tokenForHeader
              ? {
                  Authorization: "Bearer " + tokenForHeader,
                }
              : {}),
          },
        }
      );
      const apiData = await apiResponse.json();
      localStorage.setItem("USER_NAME", userInfo?.input_name);
      localStorage.setItem("USER_QR_COLOR", apiData?.hex_color_qr);
      localStorage.setItem("USER_COLOR", apiData?.hex_color);
      localStorage.setItem("USER_CHARA_TAGLINE", apiData?.param8);
      localStorage.setItem("USER_NATIVE_TYPE", apiData?.param7);
      try {
      const response = await fetch(`${otaQuizBackendBaseUrl.url}/user/store/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name:userInfo?.input_name, color:apiData?.hex_color, userType:apiData?.param7 }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("User updated/created successfully:", data);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
    })();
  }, []);



  const userNativeType = localStorage.getItem("USER_NATIVE_TYPE");
  const userColor = localStorage.getItem("USER_COLOR");

  const bottomNavigationConfig = [
    // {
    //   path: "/self-matching",
    //   name: t("bottom_nav_diagnose"),
    //   icon: assignmentGrey,
    //   activeIcon: assignmentBlack,
    //   customClass: "self-diagnosis",
    // },
    {
      path: "/home",
      name: t("bottom_nav_home"),
      icon: home,
      activeIcon: homeActive,
    },
    // {
    //   path: "/characters",
    //   name: t("bottom_nav_fav"),
    //   icon: fav,
    //   activeIcon: activeFav,
    // },
    {
      path: "/community",
      name: t("bottom_nav_community"),
      icon: community,
      activeIcon: communityActive,
    },
    {
      path: "/astrology",
      name: t("bottom_nav_astrology"),
      icon: astrology,
      activeIcon: astrologyActive,
    },
    {
      path: "/profile",
      name: t("bottom_nav_profile"),
      icon: `https://api.be-native.life/api/friends/charimg/${userNativeType}`,
      activeIcon: `https://api.be-native.life/api/friends/charimg/${userNativeType}`,
      showActiveBorder: true,
    },
  ];

  const handleLanguageSwitch = (lang) => {
    setCurLang(lang);
    changeLanguage(lang);
  };

  const handlePictureBook = () => navigate("/picture-book");
  const handleSocialGraph = () => navigate("/social-graph");

  return (
    <div className="relative">
      {/* <div
        onClick={handlePictureBook}
        className="w-[50px] h-[50px] rounded-full bg-white fixed right-4 top-4  flex flex-col items-center justify-center z-50 picturebook-icon"
        style={{
          border: "3px solid #FCC500",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <img src={bookIcon} className="w-6" />
      </div> */}

      {/* <div
        onClick={handleSocialGraph}
        className="w-[50px] h-[50px] rounded-full bg-white fixed right-4 top-20 flex flex-col items-center justify-center z-50 socialgraph-icon"
        style={{
          border: "3px solid #FCC500",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <img src={earthIcon} className="w-6" />
      </div> */}
      <div className="flex items-center px-3 pt-4 pb-2 gap-2 justify-center">
        <form
          className="flex items-center gap-2"
          onChange={(e) => handleLanguageSwitch(e.target.value)}
        >
          <input
            id="en-radio"
            type="radio"
            name="English"
            value="en"
            checked={curLang === "en"}
          />
          <label htmlFor="en-radio">English</label>
          <input
            id="ja-radio"
            type="radio"
            name="日本語"
            value="ja"
            checked={curLang === "ja"}
          />
          <label htmlFor="ja-radio">日本語</label>
        </form>
      </div>
      <div style={{ paddingBottom: isMobile ? "50px" : "0px" }}>{children}</div>
      {isMobile && isUserAuthenticated && !shouldNavHide ? (
        <div
          className=" w-full fixed bottom-0 bg-white flex flex-row justify-between items-center shadow-2xl rounded-t-2xl px-4 z-50"
          style={{
            boxShadow: "0px 0px 12px -2px rgba(0,0,0,0.75)",
            height: "72px",
            zIndex: 99999,
          }}
        >
          {bottomNavigationConfig?.map(
            ({
              activeIcon,
              icon,
              name,
              path,
              customClass,
              showActiveBorder = false,
            }) => {
              const isActive = location.pathname === path;
              return (
                <div
                  className={
                    `flex flex-col gap-1 items-center w-[85px] relative ${
                      isActive ? "otaFont" : "text-[#8B8B8B]"
                    } ` + customClass
                  }
                  onClick={() => navigate(path)}
                >
                  {showActiveBorder ? (
                    <div
                      style={{ background: userColor }}
                      className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-7 h-7 rounded-full opacity-40 mt-[-10px] "
                    />
                  ) : null}
                  <img
                    className="w-7 h-7 rounded-full relative"
                    src={isActive ? activeIcon : icon}
                    style={{
                      border: `1.5px solid ${
                        isActive && showActiveBorder ? "#07B9CE" : "transparent"
                      }`,
                    }}
                  />
                  <p
                    className="font-semibold"
                    style={{
                      transition: "0.2s ease",
                      fontSize: language === "en" ? "10px" : "12px",
                    }}
                  >
                    {name}
                  </p>
                </div>
              );
            }
          )}
        </div>
      ) : null}
    </div>
  );
}
